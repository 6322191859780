import * as React from 'react'
import { Layout } from '../components/layout'
import { useStaticQuery, graphql } from 'gatsby'
import { Painting } from '../components/painting'
import * as styles from '../components/paintingCategory.module.css'
import { Helmet } from 'react-helmet'

export default function LandscapesPage(props) {

	const landscapesQuery = useStaticQuery(graphql`
	query MyLandscapesQuery {
		allMarkdownRemark(filter: {frontmatter: {category: {eq: "Landscapes"}}}) {
			edges {
				node {
					frontmatter {
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
						category
						title
					}
				}
			}
		}
	}
`)

	const nodes = landscapesQuery.allMarkdownRemark.edges

	const paintings = nodes.map(painting => {
		return (
			<Painting
				img={painting.node.frontmatter.image.childImageSharp.gatsbyImageData}
				title={painting.node.frontmatter.title}
			></Painting>
		)
	})

	return (
		<Layout>
			<Helmet>
				<title>Landscapes | Bethany Rachelle</title>
			</Helmet>
			<div className={styles.category}>
				{paintings}
			</div>
		</Layout>
	)
}